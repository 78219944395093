:root {
    --footer-bg: #efefef;
    --footer-border:#dbdbdb;
    --link-color:#3799c1;
}

.footer-main {
    background-color: var(--footer-bg);
    border-top: 1px solid var(--footer-border);
    border-bottom: 1px solid var(--footer-border);

    padding: 15px;
    text-align: center;
}

.text-width {
    margin: 0 auto;
    width: 75vw;
}

.huji-text {
    color: #757b82;
    font-size: 14px;

}

.admin-text {
    color: #616871;
    font-size: 12px;
}

.rights-text {
    color: #757b82;
    font-size: 15px;
}

.huji-text a {
    color:var(--link-color);
    font-weight: 700;
}

.admin-text a {
    color: #816897;
    font-weight: 700;
}