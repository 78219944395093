@import 'Sidebar.css';


.up-nav{
    border-bottom:1px solid var(--border-color);
    background-color: var(--sidebar-bg-color);
    white-space: nowrap;
    text-align: center;
    font-size:17px;
}

.side-navigation-panel-select-option-selected{
    border-left:3px solid #c29ce7 !important;
}

.side-navigation-panel-select-option:hover{
    border-left:3px solid #6c4c8b !important;
}
