@import url('https://fonts.googleapis.com/css?family=Montserrat:600&display=swap');

.main-table{
    /* Center vertically */
    padding:10px;
    width:100vw;
}
.april-button{
    position: relative;
    display: inline-flex;
    width: 180px;
    height: 55px;
    margin: 0 15px;
    perspective: 1000px;
}
.april-button div{
    font-size: 19px;
    letter-spacing: 1px;
    transform-style: preserve-3d;
    transform: translateZ(-25px);
    transition: transform .25s;
    font-family: 'Montserrat', sans-serif;

}

.april-button div:before{
    content: "CLICK";
    position: absolute;
    height: 55px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
    box-sizing: border-box;
    /*border-radius: 2px;*/
}

.april-button div:after{
    position: absolute;
    content: "TO REVAEL";
    height: 55px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #32363d;
    box-sizing: border-box;
    /*border-radius: 2px;*/
}
.april-button div:before{
    color: #e5e5e5;
    background: #32363d;
    transform: rotateY(0deg) translateZ(20px);
}
.april-button div:after{
    color: #32363d;
    transform: rotateX(90deg) translateZ(20px);
}
.april-button div:hover{
    transform: translateZ(-25px) rotateX(-90deg);
}

