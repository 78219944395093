@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

:root{
    --header-bg:#85bfd0;
    --header-border:#6c99a7;
    --lock-border: #73a8b8;
    --lock-background: #7ab2c2;
    --mode-bg:transparent;
}

.header-line{
    line-height:55px;
    height:55px;
    width:100%;

    background-color: var(--header-bg);
    border-bottom: 1px solid var(--header-border);

    font-family: 'Bebas Neue', cursive;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 2px;
    /* text stroke */
    /*-webkit-text-stroke: 1px black;*/
    /*paint-order: stroke fill;*/
    text-shadow:
            -1px -1px 0 #000,
            0   -1px 0 #000,
            1px -1px 0 #000,
            1px  0   0 #000,
            1px  1px 0 #000,
            0    1px 0 #000,
            -1px  1px 0 #000,
            -1px  0   0 #000;

    vertical-align: middle;
}

.title-text{
    font-size:28px;
    display:inline-block;
    position:relative;
    left:15px;
    bottom:2px;
}

.lock-icon{
    border: 1px solid var(--lock-border);
    background-color: var(--lock-background);
    border-radius: 50%;
    padding: 7px;
    font-size:40px;
    display:inline-block;
    position:relative;
    left:10px;
    top:7px;
    margin-right:10px;
}

.side-icons{
    font-size:28px;
    display:inline-block;
    position:relative;
    top:5px;
    right:15px;
    float:right;
}

.night-mode{
    background-color: var(--mode-bg);
    border-radius:50%;
    box-shadow: 0 0 0 3px var(--mode-bg);
}

.facebook-link{
    background-color: #3d69ac;
    border-radius:50%;
    box-shadow: 0 0 0 3px transparent;
}

.login-icon:hover{
    background-color: grey;
}

span.space{
    margin:5px;
}

@media (max-width:320px) {
    .title-text {
        /*word-wrap:break-word;*/
        font-size:7vw;
    }
    .lock-icon {
        font-size:7vw;
    }
    .side-icons{
        font-size:7vw;
    }
}



