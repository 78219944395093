
:root {
    --sidebar-bg-color: #f5f5f5;
    --border-color: #dbdbdb;
    --sidebar-hover-color: #ececec;
    --sidebar-text: #5f6f89;
}

.side-bar {
    display:none;
    border-right: 1px solid var(--border-color);
    background-color: var(--sidebar-bg-color);
    height: 100vh;
    width: 300px;
    font-size: 17px;
    white-space: nowrap;
}

.side-navigation-panel *, .side-navigation-panel:after, .side-navigation-panel:before {
    color:var(--sidebar-text) !important;
}


.side-navigation-panel-select-option:hover{
    background-color: var(--sidebar-hover-color) !important;
}

.side-navigation-panel-select-option-selected{
    background-color: var(--sidebar-hover-color) !important;
}
