@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

:root{
    --bg-color:#e7e7e7;
}
body{
    background-color: #e7e7e7;
    background-color: var(--bg-color);
}

* {
    box-sizing: border-box;
}

.flex-container {
    /* We first create a flex layout context */
    display: flex;
}
:root{
    --header-bg:#85bfd0;
    --header-border:#6c99a7;
    --lock-border: #73a8b8;
    --lock-background: #7ab2c2;
    --mode-bg:transparent;
}

.header-line{
    line-height:55px;
    height:55px;
    width:100%;

    background-color: #85bfd0;

    background-color: var(--header-bg);
    border-bottom: 1px solid #6c99a7;
    border-bottom: 1px solid var(--header-border);

    font-family: 'Bebas Neue', cursive;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 2px;
    /* text stroke */
    /*-webkit-text-stroke: 1px black;*/
    /*paint-order: stroke fill;*/
    text-shadow:
            -1px -1px 0 #000,
            0   -1px 0 #000,
            1px -1px 0 #000,
            1px  0   0 #000,
            1px  1px 0 #000,
            0    1px 0 #000,
            -1px  1px 0 #000,
            -1px  0   0 #000;

    vertical-align: middle;
}

.title-text{
    font-size:28px;
    display:inline-block;
    position:relative;
    left:15px;
    bottom:2px;
}

.lock-icon{
    border: 1px solid #73a8b8;
    border: 1px solid var(--lock-border);
    background-color: #7ab2c2;
    background-color: var(--lock-background);
    border-radius: 50%;
    padding: 7px;
    font-size:40px;
    display:inline-block;
    position:relative;
    left:10px;
    top:7px;
    margin-right:10px;
}

.side-icons{
    font-size:28px;
    display:inline-block;
    position:relative;
    top:5px;
    right:15px;
    float:right;
}

.night-mode{
    background-color: transparent;
    background-color: var(--mode-bg);
    border-radius:50%;
    box-shadow: 0 0 0 3px transparent;
    box-shadow: 0 0 0 3px var(--mode-bg);
}

.facebook-link{
    background-color: #3d69ac;
    border-radius:50%;
    box-shadow: 0 0 0 3px transparent;
}

.login-icon:hover{
    background-color: grey;
}

span.space{
    margin:5px;
}

@media (max-width:320px) {
    .title-text {
        /*word-wrap:break-word;*/
        font-size:7vw;
    }
    .lock-icon {
        font-size:7vw;
    }
    .side-icons{
        font-size:7vw;
    }
}





:root {
    --sidebar-bg-color: #f5f5f5;
    --border-color: #dbdbdb;
    --sidebar-hover-color: #ececec;
    --sidebar-text: #5f6f89;
}

.side-bar {
    display:none;
    border-right: 1px solid #dbdbdb;
    border-right: 1px solid var(--border-color);
    background-color: #f5f5f5;
    background-color: var(--sidebar-bg-color);
    height: 100vh;
    width: 300px;
    font-size: 17px;
    white-space: nowrap;
}

.side-navigation-panel *, .side-navigation-panel:after, .side-navigation-panel:before {
    color:#5f6f89 !important;
    color:var(--sidebar-text) !important;
}


.side-navigation-panel-select-option:hover{
    background-color: #ececec !important;
    background-color: var(--sidebar-hover-color) !important;
}

.side-navigation-panel-select-option-selected{
    background-color: #ececec !important;
    background-color: var(--sidebar-hover-color) !important;
}

.up-nav{
    border-bottom:1px solid var(--border-color);
    background-color: var(--sidebar-bg-color);
    white-space: nowrap;
    text-align: center;
    font-size:17px;
}

.side-navigation-panel-select-option-selected{
    border-left:3px solid #c29ce7 !important;
}

.side-navigation-panel-select-option:hover{
    border-left:3px solid #6c4c8b !important;
}


:root {
    --confession-table-bg: #f5f5f5;
    --confession-table-border: #dbdbdb;
    --confession-table-title: #4a5465;
}

.content-table {
    background-color: #f5f5f5;
    background-color: var(--confession-table-bg);
    padding: 25px;
    border: 1px solid #dbdbdb;
    border: 1px solid var(--confession-table-border);
    border-radius: 10px;
    margin: 10px 10px 10px 10px;

}

.confession-box-title {
    color: #4a5465;
    color: var(--confession-table-title);
    text-align: center;
}

.title {
    margin: 13px 5px 5px;
    position: relative;
    bottom: 3px;
    display: inline-block;
    font-size: 25px;
}

.modal {
    padding: 10px;
    text-align: center;
    background-color: #f5f5f5;
    background-color: var(--confession-table-bg);
    border: 1px solid #dbdbdb;
    border: 1px solid var(--confession-table-border);
    border-radius: 10px;
}

.header {
    font-size:21px;
    padding:5px;
    border-bottom:1px solid #68b370;
}

.content{
    padding-top:10px;
}
.modal.actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 7px 10px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #f5f5f5;
    background: var(--confession-table-bg);
    border-radius: 50px;
    border: 1px solid #dbdbdb;
    border: 1px solid var(--confession-table-border);
}

.popup-content {
    color: #4a5465;
    color: var(--confession-table-title);
    background-color: transparent !important;
    border: none !important;

}


:root{
    --switch-text:#323842;
}
.switch-text{
    font-size:14px;
    width:100px;
    text-align:center;
    color: #323842;
    color: var(--switch-text);
}
.main-table{
    /* Center vertically */
    padding:10px;
    width:100vw;
}
.april-button{
    position: relative;
    display: inline-flex;
    width: 180px;
    height: 55px;
    margin: 0 15px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
}
.april-button div{
    font-size: 19px;
    letter-spacing: 1px;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-transform: translateZ(-25px);
            transform: translateZ(-25px);
    transition: -webkit-transform .25s;
    transition: transform .25s;
    transition: transform .25s, -webkit-transform .25s;
    font-family: 'Montserrat', sans-serif;

}

.april-button div:before{
    content: "CLICK";
    position: absolute;
    height: 55px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
    box-sizing: border-box;
    /*border-radius: 2px;*/
}

.april-button div:after{
    position: absolute;
    content: "TO REVAEL";
    height: 55px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #32363d;
    box-sizing: border-box;
    /*border-radius: 2px;*/
}
.april-button div:before{
    color: #e5e5e5;
    background: #32363d;
    -webkit-transform: rotateY(0deg) translateZ(20px);
            transform: rotateY(0deg) translateZ(20px);
}
.april-button div:after{
    color: #32363d;
    -webkit-transform: rotateX(90deg) translateZ(20px);
            transform: rotateX(90deg) translateZ(20px);
}
.april-button div:hover{
    -webkit-transform: translateZ(-25px) rotateX(-90deg);
            transform: translateZ(-25px) rotateX(-90deg);
}



:root {
    --confession-table-bg: #f5f5f5;
    --confession-table-border: #dbdbdb;
    --confession-table-title: #4a5465;
}

.conf-grid {
    justify-content: center;
}

.conf-card {
    width: 30%;
    height: 200px;
    border: 1px solid #dbdbdb;
    border: 1px solid var(--confession-table-border);
    padding: 20px;
    background-color: #f5f5f5;
    background-color: var(--confession-table-bg);
    margin: 20px;
    color: #4a5465;
    color: var(--confession-table-title);
    border-radius: 10px;
    overflow:scroll;
}


.delete-icon:hover{
    background-color: grey;
}

.footer{
    margin-top:15px;
    border-top:1px solid #dbdbdb;
    border-top:1px solid var(--confession-table-border);
    text-align: center;
}

:root {
    --confession-table-title: #4a5465;
    --confession-table-bg: #f5f5f5;
}

.list-item-title {
    color: #4a5465;
    color: var(--confession-table-title);
    margin: 10px 10px 10px 10px;
}

.emergency-link a {
    margin: 5px;
    color: #89939d;
    text-decoration: none;
}


.emergency-link a:hover {
    transition: 0.3s;
    border-bottom: 3px solid var(--link-color);
}
:root {
    --footer-bg: #efefef;
    --footer-border:#dbdbdb;
    --link-color:#3799c1;
}

.footer-main {
    background-color: #efefef;
    background-color: var(--footer-bg);
    border-top: 1px solid #dbdbdb;
    border-top: 1px solid var(--footer-border);
    border-bottom: 1px solid #dbdbdb;
    border-bottom: 1px solid var(--footer-border);

    padding: 15px;
    text-align: center;
}

.text-width {
    margin: 0 auto;
    width: 75vw;
}

.huji-text {
    color: #757b82;
    font-size: 14px;

}

.admin-text {
    color: #616871;
    font-size: 12px;
}

.rights-text {
    color: #757b82;
    font-size: 15px;
}

.huji-text a {
    color:#3799c1;
    color:var(--link-color);
    font-weight: 700;
}

.admin-text a {
    color: #816897;
    font-weight: 700;
}
