
:root {
    --confession-table-bg: #f5f5f5;
    --confession-table-border: #dbdbdb;
    --confession-table-title: #4a5465;
}

.conf-grid {
    justify-content: center;
}

.conf-card {
    width: 30%;
    height: 200px;
    border: 1px solid var(--confession-table-border);
    padding: 20px;
    background-color: var(--confession-table-bg);
    margin: 20px;
    color: var(--confession-table-title);
    border-radius: 10px;
    overflow:scroll;
}


.delete-icon:hover{
    background-color: grey;
}

.footer{
    margin-top:15px;
    border-top:1px solid var(--confession-table-border);
    text-align: center;
}